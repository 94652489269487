import { checkIsBusinessUser, getUserData } from "selectors/user";
import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import { getTextsData } from "./texts";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import IntegrationServices from "const/IntegrationServices";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import moment from "moment";

const { TASK_STATUSES, TASK_TYPES } = DataConstants;

const STATUSES_ORDER = [
  TASK_STATUSES.TO_DO,
  TASK_STATUSES.IN_PROGRESS,
  TASK_STATUSES.ON_HOLD,
  TASK_STATUSES.COMPLETED
];

export const getTasksRawData = ({ tasks: { data } }) => data;

export const checkTasksFetching = ({ tasks: { fetchingData } }) => fetchingData;

export const checkTasksReadyToDisplay = ({ tasks: { tasksReadyToDisplay } }) => tasksReadyToDisplay;

export const getAutoTasksRawData = createSelector(
  [getTasksRawData],
  (data) => data.filter(({ type = TASK_TYPES.CUSTOM }) => type && type !== TASK_TYPES.CUSTOM)
);

export const getTasksDataWithTargetUser = createSelector(
  [
    getTasksRawData,
    getActiveUsersData,
    checkIsBusinessUser
  ],
  (data, users, businessUser) => {
    return data
      .filter(({ type = TASK_TYPES.CUSTOM }) => !businessUser || type === TASK_TYPES.CUSTOM)
      .map(({ createdBy, targetUser, type = TASK_TYPES.CUSTOM, ...restData }) => {
        const createdByUserData = users.find(({ id }) => id === createdBy) || null;

        const targetUserData = users.find(({ id }) => id === targetUser) || null;

        return {
          ...restData,
          type,
          createdBy: createdByUserData,
          targetUser: targetUserData,
          autoTask: type !== TASK_TYPES.CUSTOM
        };
      });
  }
);

export const getTasksDataWithAutoTask = createSelector(
  [
    getTasksDataWithTargetUser,
    getTextsData,
    ({ businesses: { data } }) => data,
    ({ businesses: { selectedBusinessId } }) => selectedBusinessId
  ],
  (tasks, { uiTexts, messages }, businesses, selectedBusinessId) => {
    const { extraData: { integrationService } = {} } = businesses.find(({ id }) => id === selectedBusinessId) || {};

    const { shortLabel: serviceName } = IntegrationServices.getByValue(integrationService) || {};

    return tasks.map((task) => {
      const { status, type = TASK_TYPES.CUSTOM } = task;

      if (type === TASK_TYPES.CUSTOM) return task;

      if (status === TASK_STATUSES.COMPLETED && (type !== TASK_TYPES.BANK_FEEDS)) return null;

      const monthYearStartDateText = task.startDate
        ? moment(task.startDate).format(Constants.DATETIME_FORMATS.MONTH_AND_YEAR_TEXT)
        : "";

      return {
        ...task,
        title: ({
          [TASK_TYPES.TRANSACTIONS]: Utils.replaceTextVars(
            uiTexts.categorizeTransactionsForDate,
            { date: monthYearStartDateText }
          ),
          [TASK_TYPES.DOCUMENTS]: Utils.replaceTextVars(
            uiTexts.reviewUploadedDocumentsForDate,
            { date: monthYearStartDateText }
          ),
          [TASK_TYPES.AUDIT]: Utils.replaceTextVars(
            uiTexts.resolveCleanUpIssuesForDate,
            { date: monthYearStartDateText }
          ),
          [TASK_TYPES.BANK_FEEDS]: Utils.replaceTextVars(
            uiTexts.renewServiceBankConnections,
            { serviceName }
          )
        })[type] || "",
        text: ({
          [TASK_TYPES.TRANSACTIONS]: messages.uncategorizedAutoTaskDescription,
          [TASK_TYPES.DOCUMENTS]: messages.ocrAiAutoTaskDescription,
          [TASK_TYPES.AUDIT]: messages.cleanUpAutoTaskDescription,
          [TASK_TYPES.BANK_FEEDS]: messages.bankFeedsAutoTaskDescription
        })[type] || ""
      };
    }).filter(Boolean);
  }
);

export const getTasksData = createSelector(
  [getTasksDataWithAutoTask, getUserData],
  (tasks, user) => {
    return Utils.arraySort(tasks, [
      ({ status }) => STATUSES_ORDER.indexOf(status),
      ({ dueDate }) => dueDate ? moment.utc(dueDate).unix() : Infinity,
      ({ startDate }) => startDate ? moment.utc(startDate).unix() : Infinity,
      ({ targetUser }) => targetUser?.id === user.id || (!targetUser && !UserRoles.checkIsBusiness(user.role))
    ], [true, true, true, true, false]);
  }
);
