import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import moment from "moment";

const {
  DATETIME_FORMATS: { API_MONTH_DATE },
  AUDIT_SECTIONS_DATA: {
    TRANSACTIONS: {
      AMOUNT_DEVIATION,
      DUPLICATES,
      NO_ATTACHMENT,
      NO_CLASS,
      NO_LOCATION,
      NO_PAYEE,
      NO_PROJECT,
      POSTED_TO_PARENT,
      SIMILAR_PAYEE,
      UNUSUAL_AMOUNT,
      UNUSUAL_CATEGORY,
      UNUSUAL_TAX
    }
  }
} = Constants;

const {
  TASK_TYPES,
  STATUSES: { TO_RECONCILE, NEED_REACTION, TO_REVIEW }
} = DataConstants;

const AUDIT_TO_CHECK = [
  AMOUNT_DEVIATION,
  DUPLICATES,
  NO_ATTACHMENT,
  NO_CLASS,
  NO_LOCATION,
  NO_PAYEE,
  NO_PROJECT,
  POSTED_TO_PARENT,
  SIMILAR_PAYEE,
  UNUSUAL_AMOUNT,
  UNUSUAL_CATEGORY,
  UNUSUAL_TAX
];

export default class Tasks {
  static checkIsAutoTaskCompleted({ type, startDate, globalStats, accountsData }) {
    const monthKey = moment(startDate).format(API_MONTH_DATE);

    const typeStats = globalStats[type] || {};

    switch (type) {
      case TASK_TYPES.TRANSACTIONS:
        return ![TO_RECONCILE, NEED_REACTION, TO_REVIEW].some((status) => typeStats[status]?.byMonth?.[monthKey]?.count);
      case TASK_TYPES.DOCUMENTS:
        return ![TO_REVIEW].some((status) => typeStats[status]?.byMonth?.[monthKey]?.count);
      case TASK_TYPES.AUDIT:
        return !AUDIT_TO_CHECK.some((audit) => typeStats[audit]?.byMonth?.[monthKey]?.count);
      case TASK_TYPES.BANK_FEEDS:
        return !(accountsData.filter(({ extraData = {} }) => {
          return extraData.xeroBankFeedDisconnected || extraData.quickBooksBankFeedDisconnected;
        }).length);
      default:
        return false;
    }
  }
}
