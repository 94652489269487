import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import objectHash from "object-hash";

export default class AccountsActions {
  static FETCH_ACCOUNTS_LIST_START = "accounts/FETCH_ACCOUNTS_LIST_START";

  static FETCH_ACCOUNTS_LIST_DONE = "accounts/FETCH_ACCOUNTS_LIST_DONE";

  static FETCH_ACCOUNTS_LIST_ERROR = "accounts/FETCH_ACCOUNTS_LIST_ERROR";

  static FETCH_SOURCES_LIST_START = "accounts/FETCH_SOURCES_LIST_START";

  static FETCH_SOURCES_LIST_DONE = "accounts/FETCH_SOURCES_LIST_DONE";

  static FETCH_SOURCES_LIST_ERROR = "accounts/FETCH_SOURCES_LIST_ERROR";

  static ADD_NEW_ACCOUNT_START= "accounts/addNewAccountStart";

  static ADD_NEW_ACCOUNT_DONE = "accounts/ADD_NEW_ACCOUNT_DONE";

  static ADD_NEW_ACCOUNT_ERROR = "accounts/ADD_NEW_ACCOUNT_ERROR";

  static EDIT_ACCOUNT_START = "accounts/EDIT_ACCOUNT_START";

  static EDIT_ACCOUNT_DONE = "accounts/EDIT_ACCOUNT_DONE";

  static EDIT_ACCOUNT_ERROR = "accounts/EDIT_ACCOUNT_ERROR";

  static DELETE_ACCOUNT_START= "accounts/deleteAccountStart";

  static DELETE_ACCOUNT_DONE = "accounts/DELETE_ACCOUNT_DONE";

  static DELETE_ACCOUNT_ERROR = "accounts/DELETE_ACCOUNT_ERROR";

  static fetchAccountsList(clearList = false, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: AccountsActions.FETCH_ACCOUNTS_LIST_START, payload: { clearList, backgroundUpdate } });

      const { ACCOUNTS, BUSINESSES } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const { results: accounts } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId + ACCOUNTS}`);

      if (Array.isArray(accounts)) {
        dispatch({
          type: AccountsActions.FETCH_ACCOUNTS_LIST_DONE,
          payload: { accounts, dataHash: objectHash(accounts) }
        });

        return accounts;
      }
      dispatch({ type: AccountsActions.FETCH_ACCOUNTS_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingAccounts);

      return null;
    };
  }

  static fetchSourcesList() {
    return async(dispatch, getState) => {
      dispatch({ type: AccountsActions.FETCH_SOURCES_LIST_START });

      const { ACCOUNTS, SOURCES } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { results: sources } = await mainApi.get(ACCOUNTS + SOURCES);

      if (Array.isArray(sources) && sources.length) {
        dispatch({ type: AccountsActions.FETCH_SOURCES_LIST_DONE, payload: { sources } });

        return sources;
      }
      dispatch({ type: AccountsActions.FETCH_SOURCES_LIST_ERROR });
      toast.error(errors.whileLoadingSources);

      return null;
    };
  }

  static addNewAccount(accountData) {
    return async(dispatch, getState) => {
      dispatch({ type: AccountsActions.ADD_NEW_ACCOUNT_START });

      const { ACCOUNTS, BUSINESSES } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const account = await mainApi.put(`${BUSINESSES}/${selectedBusinessId + ACCOUNTS}`, null, accountData);

      if (account.id) {
        dispatch({ type: AccountsActions.ADD_NEW_ACCOUNT_DONE, payload: { account } });
        toast.success(messages.newAccountAdded);

        return account;
      }
      dispatch({ type: AccountsActions.ADD_NEW_ACCOUNT_ERROR });
      toast.error(errors.whileAddingAccount);

      return null;
    };
  }

  static editAccount(accountId, accountData) {
    return async(dispatch, getState) => {
      dispatch({ type: AccountsActions.EDIT_ACCOUNT_START });

      const { messages, errors } = getTextsData(getState());

      const account = await mainApi.patch(`${MainApiRoutes.ACCOUNTS}/${accountId}`, null, accountData);

      dispatch({ type: AccountsActions.EDIT_ACCOUNT_DONE, payload: { account } });
      if (account.id) {
        dispatch({ type: AccountsActions.EDIT_ACCOUNT_DONE, payload: { account } });
        toast.success(messages.accountEdited);

        return account;
      }
      dispatch({ type: AccountsActions.EDIT_ACCOUNT_ERROR });
      toast.error(errors.whileEditingAccount);

      return null;
    };
  }

  static deleteAccount(accountId) {
    return async(dispatch, getState) => {
      dispatch({ type: AccountsActions.DELETE_ACCOUNT_START });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.ACCOUNTS}/${accountId}`);

      if (ok) {
        dispatch({ type: AccountsActions.DELETE_ACCOUNT_DONE, payload: { accountId } });
        toast.success(messages.accountDeleted);

        return accountId;
      }
      dispatch({ type: AccountsActions.EDIT_ACCOUNT_ERROR });
      toast.error(errors.whileDeletingAccount);

      return null;
    };
  }
}
