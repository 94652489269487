import Css from "./style.module.scss";

import React, { useCallback } from "react";
import classNames from "classnames";

const Button = (props) => {
  const {
    type = "button",
    tagName: Tag = "button",
    icon: Icon,
    value,
    primary,
    secondary,
    success,
    danger,
    accent,
    warning,
    outline,
    light,
    dark,
    highlight,
    large,
    small,
    block,
    loading,
    disabled,
    className,
    children,
    onClick,
    ...restProps
  } = props;

  const handleClick = useCallback((event) => {
    if (onClick) onClick(event, value);
  }, [value, onClick]);

  return (
    <Tag
      className={classNames({
        [Css.button]: true,
        [Css.primary]: primary,
        [Css.secondary]: secondary,
        [Css.success]: success,
        [Css.danger]: danger,
        [Css.accent]: accent,
        [Css.warning]: warning,
        [Css.highlight]: highlight,
        [Css.outline]: outline,
        [Css.light]: light,
        [Css.dark]: dark,
        [Css.large]: large,
        [Css.small]: small,
        [Css.block]: block,
        [Css.loading]: loading,
        [className]: className
      })}
      onClick={handleClick}
      type={type}
      disabled={disabled || loading}
      {...restProps}>
      {Icon && (<Icon className={Css.icon} />)}
      {children}
    </Tag>
  );
};

export default React.memo(Button);
