export default class UiRoutes {
  static ACCOUNTS = "/accounts";

  static ACTIVITY = "/activity";

  static ARCHIVE = "/archive";

  static AUDIT = "/audit";

  static AUDIT = "/audit";

  static BUSINESSES = "/businesses";

  static CONTACTS = "/contacts";

  static DASHBOARD = "/dashboard";

  static DOCUMENTS = "/documents";

  static GUEST_USERS = "/guest-users";

  static INBOX = "/inbox";

  static INTEGRATIONS = "/integrations";

  static LOGIN = "/login";

  static LOGOUT = "/logout";

  static MAIN = "/";

  static PREFERENCES = "/preferences";

  static REPORTS = "/reports";

  static SETTINGS = "/settings";

  static SETTINGS = "/settings";

  static SIGNUP = "/signup";

  static TASKS = "/tasks";

  static TASKS = "/tasks";

  static TRANSACTIONS = "/transactions";

  static UPLOAD = "/upload";

  static USERS = "/users";
}
