import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import Async from "utils/Async";
import BusinessesActions from "./BusinessesActions";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import objectHash from "object-hash";

export default class TransactionsActions {
  static FETCH_TRANSACTIONS_LIST_START = "transactions/FETCH_TRANSACTIONS_LIST_START";

  static FETCH_TRANSACTIONS_LIST_DONE = "transactions/FETCH_TRANSACTIONS_LIST_DONE";

  static FETCH_TRANSACTIONS_LIST_ERROR = "transactions/FETCH_TRANSACTIONS_LIST_ERROR";

  static FETCH_TRANSACTIONS_TAGS_LIST_START = "transactions/FETCH_TRANSACTIONS_TAGS_TAGS_LIST_START";

  static FETCH_TRANSACTIONS_TAGS_LIST_DONE = "transactions/FETCH_TRANSACTIONS_TAGS_LIST_DONE";

  static FETCH_TRANSACTIONS_TAGS_LIST_ERROR = "transactions/FETCH_TRANSACTIONS_TAGS_LIST_ERROR";

  static FETCH_TRANSACTIONS_REASONS_LIST_START = "transactions/FETCH_TRANSACTIONS_REASONS_TAGS_LIST_START";

  static FETCH_TRANSACTIONS_REASONS_LIST_DONE = "transactions/FETCH_TRANSACTIONS_REASONS_LIST_DONE";

  static FETCH_TRANSACTIONS_REASONS_LIST_ERROR = "transactions/FETCH_TRANSACTIONS_REASONS_LIST_ERROR";

  static ADD_NEW_TRANSACTION_START = "transactions/ADD_NEW_TRANSACTION_START";

  static ADD_NEW_TRANSACTION_DONE = "transactions/ADD_NEW_TRANSACTION_DONE";

  static ADD_NEW_TRANSACTION_ERROR= "transactions/ADD_NEW_TRANSACTION_ERROR";

  static IMPORT_TRANSACTIONS_START = "transactions/IMPORT_TRANSACTIONS_START";

  static IMPORT_TRANSACTIONS_DONE = "transactions/IMPORT_TRANSACTIONS_DONE";

  static IMPORT_TRANSACTIONS_ERROR = "transactions/IMPORT_TRANSACTIONS_ERROR";

  static EDIT_TRANSACTION_START = "transactions/EDIT_TRANSACTION_START";

  static EDIT_TRANSACTION_DONE = "transactions/EDIT_TRANSACTION_DONE";

  static EDIT_TRANSACTION_ERROR = "transactions/EDIT_TRANSACTION_ERROR";

  static DELETE_TRANSACTION_START = "transactions/DELETE_TRANSACTION_START";

  static DELETE_TRANSACTION_DONE = "transactions/DELETE_TRANSACTION_DONE";

  static DELETE_TRANSACTION_ERROR = "transactions/DELETE_TRANSACTION_ERROR";

  static BULK_TRANSACTIONS_UPDATE_START = "transactions/BULK_TRANSACTIONS_UPDATE_START";

  static BULK_TRANSACTIONS_UPDATE_DONE = "transactions/BULK_TRANSACTIONS_UPDATE_DONE";

  static BULK_TRANSACTIONS_UPDATE_ERROR = "transactions/BULK_TRANSACTIONS_UPDATE_ERROR";

  static LOCK_TRANSACTION = "transactions/LOCK_TRANSACTION";

  static UNLOCK_TRANSACTION = "transactions/UNLOCK_TRANSACTION";

  static fetchTransactionsList(
    status,
    filters,
    sortings,
    offset,
    limit = Constants.TABLE_PAGE_SIZE,
    clearList = false,
    backgroundUpdate = false
  ) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_LIST_START, payload: { clearList, backgroundUpdate } });

      const { BUSINESSES, TRANSACTIONS, STATS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const path = `${BUSINESSES}/${selectedBusinessId}${TRANSACTIONS}`;

      const requestBody = { ...filters, sortings, limit, offset };

      if (status) requestBody.status = status;

      const { results: transactions, hash } = await mainApi.post(path, null, requestBody);

      // TODO: Return stats in get list response
      const stats = transactions ? await mainApi.get(path + STATS, filters) : {};

      if (Array.isArray(transactions) && stats.total) {
        dispatch({
          type: TransactionsActions.FETCH_TRANSACTIONS_LIST_DONE,
          payload: {
            transactions,
            stats,
            status,
            filters,
            backgroundUpdate,
            dataHash: objectHash({ value: hash || transactions, stats })
          }
        });

        return transactions;
      }
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingTransactions);

      return null;
    };
  }

  static fetchTransactionsTagsList() {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_TAGS_LIST_START });

      const { BUSINESSES, TRANSACTIONS, TAGS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { results: tags } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId + TRANSACTIONS + TAGS}`);

      if (Array.isArray(tags)) {
        dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_TAGS_LIST_DONE, payload: { tags } });

        return tags;
      }
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_TAGS_LIST_ERROR });

      return null;
    };
  }

  static fetchTransactionsReasonsList(searchTexts, withCodeOnly = false) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_REASONS_LIST_START });

      const { BUSINESSES, TRANSACTIONS, REASONS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { results: reasons } = await mainApi.post(
        `${BUSINESSES}/${selectedBusinessId + TRANSACTIONS + REASONS}`,
        null,
        { searchTexts, withCodeOnly }
      );

      if (Array.isArray(reasons)) {
        dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_REASONS_LIST_DONE, payload: { reasons } });

        return reasons;
      }
      dispatch({ type: TransactionsActions.FETCH_TRANSACTIONS_REASONS_LIST_ERROR });

      return null;
    };
  }

  static importTransactions(accountId, file) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.IMPORT_TRANSACTIONS_START });

      let statusCheckRequestsCounter = 0;

      let importSuccessful = false;

      const { MAX_STATUS_CHECK_REQUESTS, STATUS_CHECK_REQUEST_DELAY } = DataConstants.IMPORT_TRANSACTIONS_CONF;

      const { ACCOUNTS, TRANSACTIONS, IMPORT } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const path = `${ACCOUNTS}/${accountId}${TRANSACTIONS + IMPORT}`;

      const { resultKey } = await mainApi.put(path, null, file, "document");

      if (resultKey) {
        toast.info(Utils.replaceTextVars(messages.fileUploaded, { fileName: file.name }));
        await Async.loopWhile(
          async() => {
            const { status } = await mainApi.get(path, { resultKey }, file);

            if (status === "completed") {
              importSuccessful = true;

              return false;
            }
            if (status === "inprogress") {
              await Async.delay(STATUS_CHECK_REQUEST_DELAY);
              statusCheckRequestsCounter++;

              return ++statusCheckRequestsCounter !== MAX_STATUS_CHECK_REQUESTS;
            }

            return false;
          }
        );
      }
      if (importSuccessful) {
        dispatch({ type: TransactionsActions.IMPORT_TRANSACTIONS_DONE, payload: { fileName: file.name } });
        toast.success(Utils.replaceTextVars(messages.transactionsImported, { fileName: file.name }));

        return { fileName: file.name };
      }
      dispatch({ type: TransactionsActions.IMPORT_TRANSACTIONS_ERROR });
      toast.error(Utils.replaceTextVars(errors.whileImportingTransactions, { fileName: file.name }));

      return null;
    };
  }

  static addNewTransaction(accountId, transactionData) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.ADD_NEW_TRANSACTION_START });

      const { ACCOUNTS, TRANSACTIONS } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const transaction = await mainApi.put(`${ACCOUNTS}/${accountId + TRANSACTIONS}`, null, transactionData);

      if (transaction.id) {
        dispatch({ type: TransactionsActions.ADD_NEW_TRANSACTION_DONE, payload: { transaction } });
        toast.success(messages.transactionAdded);

        return transaction;
      }
      dispatch({ type: TransactionsActions.ADD_NEW_TRANSACTION_ERROR });
      toast.error(errors.whileAddingTransaction);

      return null;
    };
  }

  static editTransaction(transactionId, transactionData, backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.EDIT_TRANSACTION_START, payload: { backgroundUpdate } });

      const { TRANSACTIONS } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const transaction = await mainApi.patch(`${TRANSACTIONS}/${transactionId}`, null, transactionData);

      if (transaction.id) {
        dispatch({ type: TransactionsActions.EDIT_TRANSACTION_DONE, payload: { transaction } });
        dispatch(BusinessesActions.fetchGlobalStats());
        if (!silentUpdate) toast.success(messages.transactionEdited);

        return transaction;
      }
      dispatch({ type: TransactionsActions.EDIT_TRANSACTION_ERROR });
      toast.error(errors.whileEditingTransaction);

      return null;
    };
  }

  static deleteTransaction(transactionId, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.DELETE_TRANSACTION_START });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.TRANSACTIONS}/${transactionId}`);

      if (ok) {
        dispatch({ type: TransactionsActions.DELETE_TRANSACTION_DONE, payload: { transactionId } });
        if (!silentUpdate) toast.success(messages.transactionDeleted);

        return transactionId;
      }
      dispatch({ type: TransactionsActions.DELETE_TRANSACTION_ERROR });
      toast.error(errors.whileDeletingTransaction);

      return null;
    };
  }

  static bulkTransactionsUpdate(simpleUpdate, fullUpdate = null) {
    return async(dispatch, getState) => {
      dispatch({ type: TransactionsActions.BULK_TRANSACTIONS_UPDATE_START });

      const { TRANSACTIONS, BULK } = MainApiRoutes;

      const {
        FULL_UPDATE,
        CHANGE_REASON,
        CHANGE_STATUS,
        ADD_TAGS,
        LINK_VENDOR,
        SELECT_CATEGORY,
        SELECT_CLASS,
        SELECT_LOCATION,
        SELECT_PROJECT,
        SELECT_TAX_RATE,
        RESET_AND_REDO,
        REMOVE
      } = DataConstants.BULK_ACTIONS;

      const { uiTexts, messages, errors } = getTextsData(getState());

      const { data: simpleUpdateData = {}, silently } = simpleUpdate || {};

      const transactionIds = fullUpdate ? fullUpdate.map(({ id }) => id) : simpleUpdate.ids;

      const action = fullUpdate
        ? FULL_UPDATE
        : [
          simpleUpdateData.status && CHANGE_STATUS,
          simpleUpdateData.category && SELECT_CATEGORY,
          simpleUpdateData.class && SELECT_CLASS,
          simpleUpdateData.location && SELECT_LOCATION,
          simpleUpdateData.project && SELECT_PROJECT,
          simpleUpdateData.taxRate && SELECT_TAX_RATE,
          simpleUpdateData.tags && ADD_TAGS,
          simpleUpdateData.vendorId && LINK_VENDOR,
          simpleUpdateData.reason && CHANGE_REASON,
          simpleUpdateData.reset && RESET_AND_REDO,
          REMOVE
        ].find((bulkAction) => bulkAction);

      const resetAndRedoAction = action === RESET_AND_REDO;

      const requestData = fullUpdate
        ? { action, fullUpdate }
        : { action, ids: transactionIds, ...simpleUpdateData };

      const { ok, failedTxs = [] } = await mainApi.patch(TRANSACTIONS + BULK, null, requestData);

      const reconciledTransactions = resetAndRedoAction ? failedTxs.filter(({ reconciled }) => reconciled) : [];

      const notFoundTransactions = resetAndRedoAction ? failedTxs.filter(({ notFound }) => notFound) : [];

      const warningTransactionsCount = reconciledTransactions.length + notFoundTransactions.length;

      const failedCount = failedTxs.length - warningTransactionsCount;

      const successCount = transactionIds.length - failedCount;

      if (ok || successCount) {
        dispatch({
          type: TransactionsActions.BULK_TRANSACTIONS_UPDATE_DONE,
          payload: { transactionIds, action }
        });
        dispatch(BusinessesActions.fetchGlobalStats());
        if (warningTransactionsCount) {
          const [
            resetAndRedoWarningA,
            resetAndRedoWarningB,
            resetAndRedoWarningC,
            resetAndRedoWarningD
          ] = messages.resetAndRedoWarning;

          const reasons = [];

          if (reconciledTransactions.length) {
            reasons.push(Utils.replaceTextVars(resetAndRedoWarningB, { transactionsCount: reconciledTransactions.length }));
          }
          if (notFoundTransactions.length) {
            reasons.push(Utils.replaceTextVars(resetAndRedoWarningC, { transactionsCount: notFoundTransactions.length }));
          }
          toast.success(Utils.replaceTextVars(
            messages.transactionsChanged,
            { successCount, totalCount: transactionIds.length }
          ));
          dispatch(UiActions.showModal(
            [resetAndRedoWarningA, ...reasons, resetAndRedoWarningD].join("\n\n"),
            uiTexts.warning,
            false,
            null
          ));
        } else if (action === REMOVE) {
          toast.success(Utils.replaceTextVars(messages.transactionsDeleted,
            { transactionsCount: transactionIds.length }));
        } else if (transactionIds.length === 1) {
          if (!silently) {
            toast.success(messages.transactionEdited);
          }
        } else if (!silently) {
          toast.success(Utils.replaceTextVars(
            messages.transactionsChanged,
            { successCount, totalCount: transactionIds.length }
          ));
        }

        return { successCount, failedTxs };
      }
      dispatch({ type: TransactionsActions.BULK_TRANSACTIONS_UPDATE_ERROR });
      if (action === REMOVE) {
        toast.error(Utils.replaceTextVars(errors.whileDeletingTransactions,
          { transactionsCount: transactionIds.length }));
      } else toast.error(Utils.replaceTextVars(errors.whileChangingTransactions,
        { transactionsCount: transactionIds.length }));

      return null;
    };
  }

  static lockTransaction(transactionId) {
    return { type: TransactionsActions.LOCK_TRANSACTION, payload: transactionId };
  }

  static unlockTransaction(transactionId) {
    return { type: TransactionsActions.UNLOCK_TRANSACTION, payload: transactionId };
  }
}
