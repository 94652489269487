import { toast } from "react-toastify";

import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import MainApiRoutes from "const/MainApiRoutes";

export default class ActivityActions {
  static FETCH_ACTIVITY_LIST_START = "audit/FETCH_ACTIVITY_LIST_START";

  static FETCH_ACTIVITY_LIST_DONE = "audit/FETCH_ACTIVITY_LIST_DONE";

  static FETCH_ACTIVITY_LIST_ERROR = "audit/FETCH_ACTIVITY_LIST_ERROR";

  static fetchActivityList({ limit, offset, type, fromDate, toDate, clearList = false, backgroundUpdate = false }) {
    return async(dispatch, getState) => {
      dispatch({ type: ActivityActions.FETCH_ACTIVITY_LIST_START, payload: { clearList, backgroundUpdate } });

      const { BUSINESSES, ACTIVITY } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const { results, hash } = await mainApi.post(
        `${BUSINESSES}/${selectedBusinessId}${ACTIVITY}`,
        null,
        { limit, offset, type, fromDate, toDate }
      );

      if (Array.isArray(results)) {
        dispatch({
          type: ActivityActions.FETCH_ACTIVITY_LIST_DONE,
          payload: { backgroundUpdate, data: results, dataHash: hash }
        });

        return results;
      }
      dispatch({ type: ActivityActions.FETCH_ACTIVITY_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingActivity);

      return null;
    };
  }
}
