import { authZeroApi } from "api";
import { getTextsData } from "selectors/texts";
import { toast } from "react-toastify";
import UiActions from "actions/UiActions";

export default class AuthZeroActions {
  static LOGIN_WITH_REDIRECT = "authZero/LOGIN_WITH_REDIRECT";

  static LOGOUT_USER = "authZero/LOGOUT_USER";

  static HANDLE_REDIRECT_CALLBACK = "authZero/HANDLE_REDIRECT_CALLBACK";

  static FETCH_AUTH_TOKEN_START = "authZero/FETCH_AUTH_TOKEN_START";

  static FETCH_AUTH_TOKEN_DONE = "authZero/FETCH_AUTH_TOKEN_DONE";

  static FETCH_USER_INFO_START = "authZero/FETCH_USER_INFO_START";

  static FETCH_USER_INFO_DONE = "authZero/FETCH_USER_INFO_DONE";

  static FETCH_USER_INFO_ERROR = "authZero/FETCH_USER_INFO_ERROR";

  static loginWithRedirect(appState) {
    return async(dispatch) => {
      dispatch({ type: AuthZeroActions.LOGIN_WITH_REDIRECT });

      const result = await authZeroApi.loginWithRedirect(appState);

      return result;
    };
  }

  static logoutUser() {
    return async(dispatch) => {
      dispatch({ type: AuthZeroActions.LOGOUT_USER });
      dispatch(UiActions.togglePreloader(true));

      let result = false;

      result = await authZeroApi.logoutUser();

      return result;
    };
  }

  static handleRedirectCallback() {
    return async(dispatch) => {
      dispatch({ type: AuthZeroActions.HANDLE_REDIRECT_CALLBACK });

      const result = await authZeroApi.handleRedirectCallback();

      return result;
    };
  }

  static fetchAuthToken(callbackState = false) {
    return async(dispatch) => {
      dispatch({ type: AuthZeroActions.FETCH_AUTH_TOKEN_START, payload: { callbackState } });

      const token = await authZeroApi.getAuthToken(callbackState);

      dispatch({ type: AuthZeroActions.FETCH_AUTH_TOKEN_DONE, payload: { token } });

      return token;
    };
  }

  static fetchUserInfo(backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: AuthZeroActions.FETCH_USER_INFO_START, payload: { backgroundUpdate } });

      const { errors } = getTextsData(getState());

      const userInfo = await authZeroApi.getUserInfo();

      if (userInfo) {
        dispatch({ type: AuthZeroActions.FETCH_USER_INFO_DONE, payload: { userInfo } });

        return userInfo;
      }
      dispatch({ type: AuthZeroActions.FETCH_USER_INFO_ERROR });

      if (!silentUpdate) toast.error(errors.whileLoadingUserInfo);

      return null;
    };
  }
}
