import { createSelector } from "reselect";
import { getActiveUsersData } from "selectors/organizations";
import Utils from "utils/Utils";

export const checkArchiveFetching = ({ archive: { fetchingData } }) => fetchingData;

export const getUploadingArchiveFilesCount = ({ archive: { uploadingCount } }) => uploadingCount;

export const getArchiveData = createSelector(
  [
    ({ archive: { data } }) => data,
    getActiveUsersData
  ],
  (data, users) => {
    return data.map((fileData) => {
      const { createdBy, sourceData } = fileData;

      return {
        ...fileData,
        createdBy: (createdBy && (Utils.arrayFind(users, "id", createdBy) || Utils.arrayFind(users, "sub", createdBy)))
        || (sourceData && sourceData.from ? { email: sourceData.from } : null)
      };
    });
  }
);
