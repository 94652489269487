import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";

export default class SummaryActions {
  static FETCH_SUMMARY_DATA_START = "summary/FETCH_SUMMARY_DATA_START";

  static FETCH_SUMMARY_DATA_DONE = "summary/FETCH_SUMMARY_DATA_DONE";

  static FETCH_SUMMARY_DATA_ERROR = "summary/FETCH_SUMMARY_DATA_ERROR";

  static SEND_EXECUTIVE_SUMMARY_START = "organizations/SEND_EXECUTIVE_SUMMARY_START";

  static SEND_EXECUTIVE_SUMMARY_DONE = "organizations/SEND_EXECUTIVE_SUMMARY_DONE";

  static SEND_EXECUTIVE_SUMMARY_ERROR = "organizations/SEND_EXECUTIVE_SUMMARY_ERROR";

  static fetchSummaryData(filters, backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: SummaryActions.FETCH_SUMMARY_DATA_START, payload: { backgroundUpdate } });

      const { ORGANIZATIONS, SUMMARY } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const { results: summary, lastUpdatedAt } = await mainApi.get(ORGANIZATIONS + SUMMARY, filters);

      if (Array.isArray(summary)) {
        dispatch({ type: SummaryActions.FETCH_SUMMARY_DATA_DONE, payload: { summary, lastUpdatedAt } });

        return summary;
      }
      dispatch({ type: SummaryActions.FETCH_SUMMARY_DATA_ERROR });
      if (!silentUpdate) toast.error(errors.whileLoadingDashboardData);

      return null;
    };
  }

  static sendExecutiveSummary(date, recipients, testMode) {
    return async(dispatch, getState) => {
      dispatch({ type: SummaryActions.SEND_EXECUTIVE_SUMMARY_START });

      const { messages, errors } = getTextsData(getState());

      const { ORGANIZATIONS, EXECUTIVE_SUMMARY } = MainApiRoutes;

      const { ok } = await mainApi.post(
        `${ORGANIZATIONS}${EXECUTIVE_SUMMARY}/${date}`,
        null,
        {
          recipients,
          ...(testMode ? { testMode } : {})
        }
      );

      if (ok) {
        dispatch({
          type: SummaryActions.SEND_EXECUTIVE_SUMMARY_DONE,
          payload: { date, recipients, testMode }
        });
        toast.success(messages.executiveSummarySent);

        return true;
      }
      dispatch({ type: SummaryActions.SEND_EXECUTIVE_SUMMARY_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }
}
