import OrganizationsActions from "actions/OrganizationsActions";
import UserActions from "actions/UserActions";

const {
  SET_BACKUP_INFO_START,
  FETCH_ORGANIZATIONS_LIST_START,
  FETCH_USERS_LIST_START,
  CHANGE_ACTIVE_ORGANIZATION_START,
  EDIT_USER_START: EDIT_ORGANIZATION_USER_START,
  ASK_BUSINESS_USER_START,
  INVITE_USER_START,
  REVOKE_USER_START,
  DELETE_BACKUP_INFO_START,
  DELETE_ORGANIZATION_START,
  DELETE_ALL_INACTIVE_ORGANIZATIONS_START,
  SET_BACKUP_INFO_DONE,
  FETCH_ORGANIZATIONS_LIST_DONE,
  FETCH_USERS_LIST_DONE,
  CHANGE_ACTIVE_ORGANIZATION_DONE,
  EDIT_USER_DONE: EDIT_ORGANIZATION_USER_DONE,
  ASK_BUSINESS_USER_DONE,
  INVITE_USER_DONE,
  REVOKE_USER_DONE,
  DELETE_BACKUP_INFO_DONE,
  DELETE_ORGANIZATION_DONE,
  DELETE_ALL_INACTIVE_ORGANIZATIONS_DONE,
  SET_BACKUP_INFO_ERROR,
  FETCH_ORGANIZATIONS_LIST_ERROR,
  FETCH_USERS_LIST_ERROR,
  CHANGE_ACTIVE_ORGANIZATION_ERROR,
  EDIT_USER_ERROR: EDIT_ORGANIZATION_USER_ERROR,
  ASK_BUSINESS_USER_ERROR,
  INVITE_USER_ERROR,
  REVOKE_USER_ERROR,
  DELETE_BACKUP_INFO_ERROR,
  DELETE_ORGANIZATION_ERROR,
  DELETE_ALL_INACTIVE_ORGANIZATIONS_ERROR,
  TOGGLE_ORGANIZATION_MFA_ERROR,
  TOGGLE_ORGANIZATION_MFA_DONE,
  TOGGLE_ORGANIZATION_MFA_START
} = OrganizationsActions;

const {
  EDIT_USER_START,
  EDIT_USER_DONE,
  EDIT_USER_ERROR
} = UserActions;

const initialState = {
  fetchingData: false,
  fetchingUsers: false,
  fetchingSettings: false,
  users: [],
  data: []
};

export default (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SET_BACKUP_INFO_START:
    case FETCH_ORGANIZATIONS_LIST_START:
    case CHANGE_ACTIVE_ORGANIZATION_START:
    case DELETE_BACKUP_INFO_START:
    case DELETE_ORGANIZATION_START:
    case DELETE_ALL_INACTIVE_ORGANIZATIONS_START:
      return { ...state, fetchingData: !payload.backgroundUpdate };

    case FETCH_USERS_LIST_START:
    case EDIT_ORGANIZATION_USER_START:
    case ASK_BUSINESS_USER_START:
    case INVITE_USER_START:
    case REVOKE_USER_START:
    case EDIT_USER_START: {
      const { backgroundUpdate } = payload || {};

      return { ...state, ...(backgroundUpdate ? {} : { fetchingUsers: true }) };
    }

    case FETCH_ORGANIZATIONS_LIST_DONE:
      return {
        ...state,
        data: payload.organizations,
        fetchingData: false
      };

    case FETCH_USERS_LIST_DONE:
      return { ...state, users: payload.users, fetchingUsers: false };

    case EDIT_ORGANIZATION_USER_DONE:
      return {
        ...state,
        fetchingUsers: false,
        users: state.users.map((user) => {
          if (user.id === payload.id) {
            return { ...user, ...payload };
          }

          return user;
        })
      };

    case EDIT_USER_DONE:
      return {
        ...state,
        fetchingUsers: false,
        users: state.users.map((user) => {
          if (user.id === payload.userId) {
            return {
              ...user,
              ...payload
            };
          }

          return user;
        })
      };

    case INVITE_USER_DONE:
      return { ...state, users: payload.userId ? [...state.users, payload] : state.users, fetchingUsers: false };

    case REVOKE_USER_DONE:
      return { ...state, users: state.users.filter(({ email }) => email !== payload.email), fetchingUsers: false };

    case SET_BACKUP_INFO_DONE:
    case ASK_BUSINESS_USER_DONE:
    case CHANGE_ACTIVE_ORGANIZATION_DONE:
    case DELETE_BACKUP_INFO_DONE:
    case DELETE_ORGANIZATION_DONE:
    case DELETE_ALL_INACTIVE_ORGANIZATIONS_DONE:
    case SET_BACKUP_INFO_ERROR:
    case FETCH_ORGANIZATIONS_LIST_ERROR:
    case CHANGE_ACTIVE_ORGANIZATION_ERROR:
    case DELETE_BACKUP_INFO_ERROR:
    case DELETE_ORGANIZATION_ERROR:
    case DELETE_ALL_INACTIVE_ORGANIZATIONS_ERROR:
      return { ...state, fetchingData: false };

    case FETCH_USERS_LIST_ERROR:
    case EDIT_ORGANIZATION_USER_ERROR:
    case ASK_BUSINESS_USER_ERROR:
    case INVITE_USER_ERROR:
    case REVOKE_USER_ERROR:
    case EDIT_USER_ERROR:
      return { ...state, fetchingUsers: false };

    case TOGGLE_ORGANIZATION_MFA_START:
      return { ...state, fetchingSettings: true };

    case TOGGLE_ORGANIZATION_MFA_DONE:
      return {
        ...state,
        fetchingSettings: false,
        data: state.data.map((organization) => {
          if (organization.id === payload.id) {
            return { ...organization, mfaEnabled: payload.mfaEnabled };
          }

          return organization;
        }) };

    case TOGGLE_ORGANIZATION_MFA_ERROR:
      return { ...state, fetchingSettings: false };

    default:
      return state;
  }
};
