import { getCommentsTargetData } from "selectors/comments";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { mainApi } from "api";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import MainApiRoutes from "const/MainApiRoutes";

export default class CommentsActions {
  static FETCH_COMMENTS_START = "comments/FETCH_COMMENTS_START";

  static FETCH_COMMENTS_DONE = "comments/FETCH_COMMENTS_DONE";

  static FETCH_COMMENTS_ERROR = "comments/FETCH_COMMENTS_ERROR";

  static FETCH_UNREAD_COMMENTS_START = "comments/FETCH_UNREAD_COMMENTS_START";

  static FETCH_UNREAD_COMMENTS_DONE = "comments/FETCH_UNREAD_COMMENTS_DONE";

  static FETCH_UNREAD_COMMENTS_ERROR = "comments/FETCH_UNREAD_COMMENTS_ERROR";

  static ADD_NEW_COMMENT_START = "comments/ADD_NEW_COMMENT_START";

  static ADD_NEW_COMMENT_DONE = "comments/ADD_NEW_COMMENT_DONE";

  static ADD_NEW_COMMENT_ERROR = "comments/ADD_NEW_COMMENT_ERROR";

  static DELETE_COMMENT_START = "comments/DELETE_COMMENT_START";

  static DELETE_COMMENT_DONE = "comments/DELETE_COMMENT_DONE";

  static DELETE_COMMENT_ERROR = "comments/DELETE_COMMENT_ERROR";

  static MARK_ALL_AS_READ_START = "comments/MARK_ALL_AS_READ_START";

  static MARK_ALL_AS_READ_DONE = "comments/MARK_ALL_AS_READ_DONE";

  static MARK_ALL_AS_READ_ERROR = "comments/MARK_ALL_AS_READ_ERROR";

  static fetchComments(targetType, targetId, clearList = false, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: CommentsActions.FETCH_COMMENTS_START, payload: { clearList, backgroundUpdate } });

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { BUSINESSES, COMMENTS } = MainApiRoutes;

      const path = `${BUSINESSES}/${selectedBusinessId + COMMENTS}/${targetType}/${targetId}`;

      const { results: comments } = await mainApi.get(path);

      if (Array.isArray(comments)) {
        dispatch({ type: CommentsActions.FETCH_COMMENTS_DONE, payload: { targetType, targetId, comments } });

        return comments;
      }
      dispatch({ type: CommentsActions.FETCH_COMMENTS_ERROR });

      return null;
    };
  }

  static fetchUnreadComments() {
    return async(dispatch) => {
      dispatch({ type: CommentsActions.FETCH_UNREAD_COMMENTS_START });

      const { COMMENTS, UNREAD } = MainApiRoutes;

      const { results: comments } = await mainApi.get(COMMENTS + UNREAD);

      if (Array.isArray(comments)) {
        dispatch({ type: CommentsActions.FETCH_UNREAD_COMMENTS_DONE, payload: { comments } });

        return comments;
      }
      dispatch({ type: CommentsActions.FETCH_UNREAD_COMMENTS_ERROR });

      return null;
    };
  }

  static addNewComment(type, text, mentioned = [], backgroundUpdate = false, ...rest) {
    return async(dispatch, getState) => {
      const { id: createdBy } = getUserData(getState());

      const { errors } = getTextsData(getState());

      const targetData = getCommentsTargetData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const [targetId = targetData.id, targetType = targetData.type] = rest;

      const commentId = uuid();

      dispatch({
        type: CommentsActions.ADD_NEW_COMMENT_START,
        payload: { targetId, targetType, type, text, commentId, createdBy, backgroundUpdate }
      });

      const { BUSINESSES, COMMENTS } = MainApiRoutes;

      const path = `${BUSINESSES}/${selectedBusinessId + COMMENTS}`;

      const comment = await mainApi.put(path, null, { targetType, targetId, type, text, mentioned });

      if (comment.id) {
        dispatch({ type: CommentsActions.ADD_NEW_COMMENT_DONE, payload: { comment } });

        return comment;
      }
      dispatch({ type: CommentsActions.ADD_NEW_COMMENT_ERROR });
      toast.error(errors.whileAddingComment);

      return null;
    };
  }

  static deleteComment(commentId) {
    return async(dispatch, getState) => {
      dispatch({ type: CommentsActions.DELETE_COMMENT_START });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.COMMENTS}/${commentId}`);

      if (ok) {
        dispatch({ type: CommentsActions.DELETE_COMMENT_DONE, payload: { commentId } });
        toast.success(messages.commentDeleted);

        return commentId;
      }
      dispatch({ type: CommentsActions.DELETE_COMMENT_ERROR });
      toast.error(errors.whileDeletingComment);

      return null;
    };
  }

  static markAllAsRead() {
    return async(dispatch, getState) => {
      dispatch({ type: CommentsActions.MARK_ALL_AS_READ_START });

      const { messages, errors } = getTextsData(getState());

      const { COMMENTS, MARK_ALL } = MainApiRoutes;

      const response = await mainApi.post(`${COMMENTS}${MARK_ALL}`);

      if (response.ok) {
        dispatch({ type: CommentsActions.MARK_ALL_AS_READ_DONE });
        toast.success(messages.markAllAsReadCompleted);

        return true;
      }
      dispatch({ type: CommentsActions.MARK_ALL_AS_READ_ERROR });
      toast.error(errors.whileMarkCommentsAsRead);

      return false;
    };
  }
}
