/* eslint-disable no-magic-numbers */
export default class DataConstants {
  static IMPORT_TRANSACTIONS_CONF = {
    MAX_STATUS_CHECK_REQUESTS: 30,
    STATUS_CHECK_REQUEST_DELAY: 3000
  };

  static STATUSES = {
    TO_EXTRACT: "toExtract",
    TO_RECONCILE: "toReconcile",
    TO_REPORT: "toReport",
    TO_REVIEW: "toReview",
    EXCLUDED: "excluded",
    EXPORTED: "exported",
    NEED_REACTION: "needReaction",
    IN_PROGRESS: "inProgress"
  };

  static BULK_ACTIONS = {
    FULL_UPDATE: "fullUpdate",
    CHANGE_PATH: "changePath",
    CHANGE_REASON: "changeReason",
    CHANGE_STATUS: "changeStatus",
    ADD_TAGS: "addTags",
    LINK_VENDOR: "linkVendor",
    SELECT_CATEGORY: "selectCategory",
    SELECT_ITEM: "selectItem",
    SELECT_CLASS: "selectClass",
    SELECT_LOCATION: "selectLocation",
    SELECT_PROJECT: "selectProject",
    SELECT_TAX_RATE: "selectTaxRate",
    SELECT_PAYMENT_ACCOUNT: "selectPaymentAccount",
    RESET_AND_REDO: "resetAndRedo",
    REMOVE: "remove"
  };

  static TRANSACTION_TYPES = {
    DEPOSIT: "deposit",
    WITHDRAW: "withdraw"
  };

  static ADVANCED_TRANSACTION_TYPES = {
    BANK_FEED: "bankFeed",
    BILL: "bill",
    BILL_PAYMENT: "billPayment",
    CHECK: "check",
    CREDIT_CARD_CHARGE: "creditCardCharge",
    CREDIT_CARD_CREDIT: "creditCardCredit",
    DEPOSIT: "deposit",
    EXPENSE: "expense",
    INCOME: "income",
    PAYMENT: "payment",
    PURCHASE: "purchase",
    RECEIVED: "received",
    RECEIVED_PAYMENT: "receivedPayment",
    REFUND_RECEIPT: "refundReceipt",
    SALES_RECEIPT: "salesReceipt",
    SPENT: "spent",
    TRANSFER: "transfer"
  };

  static DOCUMENT_TYPES = {
    INVOICE: "invoice",
    BILL: "bill",
    CREDIT_NOTE: "creditNote",
    RECEIPT: "receipt",
    SALES_RECEIPT: "salesReceipt",
    OTHER: "other"
  };

  static DOCUMENT_PAYMENT_TYPES = {
    BUY: "buy",
    SELL: "sell"
  };

  static ARCHIVE_ATTACHMENT_TYPES = {
    TAX_STATEMENT: "taxStatement",
    AGREEMENT: "agreement",
    BANK_STATEMENT: "bankStatement",
    FOLDER: "folder",
    OTHER: "other"
  };

  static CONTACT_TYPES = {
    LEGAL_PERSON: "legalPerson",
    NATURAL_PERSON: "naturalPerson"
  };

  static CONTACT_SUB_TYPES = {
    VENDOR: "vendor",
    CUSTOMER: "customer"
  };

  static COMMENT_TYPES = {
    COMMENT: "comment",
    NOTE: "note",
    TASK: "task",
    QUESTION: "question",
    ERROR: "error"
  };

  static COMMENT_TARGET_TYPES = {
    AUDIT: "audit",
    DOCUMENTS: "documents",
    TRANSACTIONS: "transactions",
    ARCHIVE: "archive",
    TASKS: "tasks"
  };

  static DOCUMENT_RECOGNISE_STATUSES = {
    ERROR: "error",
    DONE: "done"
  };

  static USER_STATUSES = {
    APPROVED: "approved",
    DECLINED: "declined",
    PENDING: "pending"
  };

  static ROBOTIC_AI_STATUSES = {
    UNKNOWN: "0:unknown",
    AWAITING_CATEGORIZATION: "1:awaitingCategorization",
    AWAITING_CLARIFICATION: "2:awaitingClarification",
    AWAITING_REVIEW: "3:awaitingReview",
    AWAITING_EXPORT: "4:awaitingExport",
    AWAITING_RECONCILIATION: "5:awaitingReconciliation"
  };

  static TASK_STATUSES = {
    TO_DO: "toDo",
    IN_PROGRESS: "inProgress",
    ON_HOLD: "onHold",
    COMPLETED: "completed"
  };

  static TASK_REPEAT_END_TYPES = {
    DATE: "date",
    OCCURRENCES: "occurrences"
  };

  static TASK_REPEAT_PERIODS = {
    HOUR: "hour",
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
    YEAR: "year"
  };

  static TASK_TYPES = {
    CUSTOM: "custom",
    TRANSACTIONS: "transactions",
    AUDIT: "audit",
    DOCUMENTS: "documents",
    BANK_FEEDS: "bankFeeds"
  };

  static GPT_FINE_TUNE_STATUSES = {
    SUCCEEDED: "SUCCEEDED"
  };

  static ACCOUNT_NAMES = {
    ACCOUNT_PAYABLE: "Account Payable",
    ACCOUNT_RECEIVABLE: "Account Receivable"
  };

  static UNCATEGORIZED_ACCOUNTS_LIST = {
    ASK_BUSINESS: "Ask Business",
    ASK_CLIENT: "Ask Client",
    ASK_MY_ACCOUNTANT: "Ask My Accountant",
    ASK_USER: "Ask User",
    CLIENT_TO_ADVISE: "Client To Advise",
    UNCATEGORISED_ASSET: "Uncategorised Asset",
    UNCATEGORISED_EXPENSE: "Uncategorised Expense",
    UNCATEGORISED_FUNDS: "Uncategorised Funds",
    UNCATEGORISED_INCOME: "Uncategorised Income",
    UNCATEGORIZED_ASSET: "Uncategorized Asset",
    UNCATEGORIZED_EXPENSE: "Uncategorized Expense",
    UNCATEGORIZED_FUNDS: "Uncategorized Funds",
    UNCATEGORIZED_INCOME: "Uncategorized Income",
    UNKNOWN_ASK: "Unknown - Ask"
  };
}
