import * as Icons from "@phosphor-icons/react";
import { memoize } from "decko";
import DataConstants from "const/DataConstants";
import UiRoutes from "const/UiRoutes";

const { STATUSES: { NEED_REACTION, TO_REVIEW } } = DataConstants;

const { TRANSACTIONS, DOCUMENTS, TASKS, ARCHIVE, DASHBOARD, CONTACTS, ACTIVITY, AUDIT, ACCOUNTS } = UiRoutes;

export default class Pages {
  static BUSINESS_PAGES_DATA = {
    [DASHBOARD]: {
      value: DASHBOARD,
      titleLangId: "dashboard",
      iconComponent: Icons.PresentationChart
    },
    [TASKS]: {
      value: TASKS,
      titleLangId: "tasks",
      statsNotificationProp: "tasks.active.count",
      statsNotificationUrlParameters: { targetUser: "currentUser" },
      iconComponent: Icons.CheckSquareOffset
    },
    [ACCOUNTS]: {
      value: ACCOUNTS,
      titleLangId: "banking",
      statsNotificationProp: "banking.count",
      iconComponent: Icons.CreditCard
    },
    [TRANSACTIONS]: {
      value: TRANSACTIONS,
      titleLangId: "accounting",
      statsNotificationProp: `transactions.${NEED_REACTION}.count`,
      statsNotificationUrlParameters: { status: NEED_REACTION },
      iconComponent: Icons.CreditCard
    },
    [AUDIT]: {
      value: AUDIT,
      titleLangId: "cleanUp",
      iconComponent: Icons.Flag,
      statsNotificationProp: "audit.total.count"
    },
    [DOCUMENTS]: {
      value: DOCUMENTS,
      titleLangId: "ocrAi",
      statsNotificationProp: `documents.${TO_REVIEW}.count`,
      statsNotificationUrlParameters: { status: TO_REVIEW },
      iconComponent: Icons.FileText
    },
    [ARCHIVE]: {
      value: ARCHIVE,
      titleLangId: "fileSharing",
      statsNotificationProp: "archive.notRead",
      iconComponent: Icons.FolderNotch
    },
    [CONTACTS]: {
      value: CONTACTS,
      titleLangId: "contacts",
      iconComponent: Icons.Users
    },
    [ACTIVITY]: {
      value: ACTIVITY,
      titleLangId: "activity",
      iconComponent: Icons.Lightning
    }
  };

  static ACCOUNTANT_PAGES_DATA = {
    ...Pages.BUSINESS_PAGES_DATA,
    [TRANSACTIONS]: {
      ...Pages.BUSINESS_PAGES_DATA[TRANSACTIONS],
      titleLangId: "uncategorized",
      statsNotificationUrlParameters: { status: TO_REVIEW },
      statsNotificationProp: "transactions.toReview.count"
    }
  };

  @memoize
  static getCurrentRouteInfo(pathName) {
    const [businessId, route, ...restPath] = pathName.substr(1).split("/");

    return {
      businessId: businessId || null,
      route: route ? `/${route}` : null,
      restPath: [...restPath].filter((value) => value)
    };
  }

  static getPageData(pageRoute, businessUser) {
    const pagesData = businessUser ? Pages.BUSINESS_PAGES_DATA : Pages.ACCOUNTANT_PAGES_DATA;

    return pageRoute ? pagesData[pageRoute] : pagesData;
  }
}
