import { getContactsData } from "selectors/contacts";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import MainApiRoutes from "const/MainApiRoutes";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import objectHash from "object-hash";

export default class ContactsActions {
  static FETCH_CONTACTS_LIST_START = "contacts/FETCH_CONTACTS_LIST_START";

  static FETCH_CONTACTS_LIST_DONE = "contacts/FETCH_CONTACTS_LIST_DONE";

  static FETCH_CONTACTS_LIST_ERROR = "contacts/FETCH_CONTACTS_LIST_ERROR";

  static FETCH_VENDORS_TAGS_LIST_START = "businesses/FETCH_VENDORS_TAGS_LIST_START";

  static FETCH_VENDORS_TAGS_LIST_DONE = "businesses/FETCH_VENDORS_TAGS_LIST_DONE";

  static FETCH_VENDORS_TAGS_LIST_ERROR = "businesses/FETCH_VENDORS_TAGS_LIST_ERROR";

  static FETCH_VENDORS_VALIDATION_STATS_START = "contacts/FETCH_VENDORS_VALIDATION_STATS_START";

  static FETCH_VENDORS_VALIDATION_STATS_DONE = "contacts/FETCH_VENDORS_VALIDATION_STATS_DONE";

  static FETCH_VENDORS_VALIDATION_STATS_ERROR = "contacts/FETCH_VENDORS_VALIDATION_STATS_ERROR";

  static STOP_VENDORS_VALIDATION_START = "contacts/STOP_VENDORS_VALIDATION_START";

  static STOP_VENDORS_VALIDATION_DONE= "contacts/STOP_VENDORS_VALIDATION_DONE";

  static STOP_VENDORS_VALIDATION_ERROR= "contacts/STOP_VENDORS_VALIDATION_ERROR";

  static LOOKUP_CONTACT_DATA_START = "contacts/LOOKUP_CONTACT_DATA_START";

  static LOOKUP_CONTACT_DATA_DONE = "contacts/LOOKUP_CONTACT_DATA_DONE";

  static LOOKUP_CONTACT_DATA_ERROR = "contacts/LOOKUP_CONTACT_DATA_ERROR";

  static ADD_NEW_CONTACT_START = "contacts/ADD_NEW_CONTACT_START";

  static ADD_NEW_CONTACT_DONE = "contacts/ADD_NEW_CONTACT_DONE";

  static ADD_NEW_CONTACT_ERROR = "contacts/ADD_NEW_CONTACT_ERROR";

  static EDIT_CONTACT_START = "contacts/EDIT_CONTACT_START";

  static EDIT_CONTACT_DONE = "contacts/EDIT_CONTACT_DONE";

  static EDIT_CONTACT_ERROR = "contacts/EDIT_CONTACT_ERROR";

  static DELETE_CONTACT_START = "contacts/DELETE_CONTACT_START";

  static DELETE_CONTACT_DONE = "contacts/DELETE_CONTACT_DONE";

  static DELETE_CONTACT_ERROR = "contacts/DELETE_CONTACT_ERROR";

  static COPY_CONTACT_START = "contacts/COPY_CONTACT_START";

  static COPY_CONTACT_DONE = "contacts/COPY_CONTACT_DONE";

  static COPY_CONTACT_ERROR = "contacts/COPY_CONTACT_ERROR";

  static fetchContactsList(clearList = false, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.FETCH_CONTACTS_LIST_START, payload: { clearList, backgroundUpdate } });

      const { BUSINESSES, VENDORS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { errors } = getTextsData(getState());

      const { results: contacts, hash } = await mainApi.get(`${BUSINESSES}/${selectedBusinessId}${VENDORS}`);

      if (Array.isArray(contacts)) {
        dispatch({
          type: ContactsActions.FETCH_CONTACTS_LIST_DONE,
          payload: { contacts, dataHash: objectHash({ value: hash || contacts }) }
        });

        return contacts;
      }
      dispatch({ type: ContactsActions.FETCH_CONTACTS_LIST_ERROR });
      toast.error(errors.whileLoadingContacts);

      return null;
    };
  }

  static fetchVendorsTagsList(vendorId) {
    return async(dispatch) => {
      dispatch({ type: ContactsActions.FETCH_VENDORS_TAGS_LIST_START });

      const { VENDORS, TAGS } = MainApiRoutes;

      const { results: vendorsTags } = await mainApi.get(`${VENDORS}/${vendorId + TAGS}`);

      if (Array.isArray(vendorsTags)) {
        dispatch({ type: ContactsActions.FETCH_VENDORS_TAGS_LIST_DONE, payload: { vendorsTags } });

        return vendorsTags;
      }
      dispatch({ type: ContactsActions.FETCH_VENDORS_TAGS_LIST_ERROR });

      return null;
    };
  }

  static fetchVendorsValidationStats(vendorIds, mergeResults = false, sparseUpdate = false, audit = false) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.FETCH_VENDORS_VALIDATION_STATS_START, payload: { mergeResults, sparseUpdate } });

      const { BUSINESSES, VENDORS, VALIDATION } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { result, hash } = await mainApi.post(
        `${BUSINESSES}/${selectedBusinessId}${VENDORS + VALIDATION}`,
        null,
        { vendorIds, audit }
      );

      if (result) {
        const {
          categories: vendorsCategories = [],
          classes: vendorsClasses = [],
          locations: vendorsLocations = [],
          projects: vendorsProjects = [],
          taxRates: vendorsTaxRates = []
        } = result;

        dispatch({
          type: ContactsActions.FETCH_VENDORS_VALIDATION_STATS_DONE,
          payload: {
            vendorsCategories,
            vendorsClasses,
            vendorsLocations,
            vendorsProjects,
            vendorsTaxRates,
            mergeResults,
            sparseUpdate,
            dataHash: objectHash({ value: hash || result })
          }
        });

        return result;
      }
      dispatch({ type: ContactsActions.FETCH_VENDORS_VALIDATION_STATS_ERROR });

      return null;
    };
  }

  static stopVendorsValidation(vendorId) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.STOP_VENDORS_VALIDATION_START });

      const { VENDORS, STOP_VALIDATION } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.post(`${VENDORS}/${vendorId}${STOP_VALIDATION}`, null, { vendorId });

      if (ok) {
        dispatch({ type: ContactsActions.STOP_VENDORS_VALIDATION_DONE, payload: { vendorId } });
        toast.success(messages.operationCompleted);

        return true;
      }
      dispatch({ type: ContactsActions.STOP_VENDORS_VALIDATION_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }

  static lookupContactData(regId) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.LOOKUP_CONTACT_DATA_START });

      const { VENDORS, LOOKUP } = MainApiRoutes;

      const { messages, errors } = getTextsData(getState());

      const contact = await mainApi.get(VENDORS + LOOKUP, { id: regId });

      if (contact && contact.name) {
        dispatch({ type: ContactsActions.LOOKUP_CONTACT_DATA_DONE, payload: { contact } });
        toast.info(messages.operationCompleted);

        return contact;
      }
      dispatch({ type: ContactsActions.LOOKUP_CONTACT_DATA_ERROR });
      toast.error(errors.unknown);

      return null;
    };
  }

  static addNewContact(contactData) {
    return async(dispatch, getState) => {
      const { messages, uiTexts, errors } = getTextsData(getState());

      const contactsList = getContactsData(getState());

      const { idNumber, vatId, name } = contactData;

      const fullMatchContact = contactsList.find((value) => {
        return Utils.checkSimilarityBy({ idNumber, vatId, name }, value, 1);
      });

      const similarContact = fullMatchContact
        ? null
        : contactsList.find((value) => Utils.checkSimilarityBy({ idNumber, vatId, name }, value));

      const existingContact = fullMatchContact || similarContact;

      if (existingContact) {
        const [similarContactFoundA, similarContactFoundB] = messages.similarContactFound;

        const modalText = [
          Object.entries({
            businessName: existingContact.name,
            type: existingContact.subType && uiTexts[existingContact.subType],
            businessId: existingContact.idNumber,
            vatId: existingContact.vatId
          }).filter(([, value]) => value).map(([label, value]) => `${uiTexts[label]}: ${value}`).join("\n"),
          similarContactFoundB
        ].join("\n\n");

        const modalResult = await dispatch(UiActions.showModal(
          modalText,
          similarContactFoundA,
          true,
          undefined,
          uiTexts.use,
          similarContact ? uiTexts.createNew : uiTexts.cancel,
          similarContact ? "createNew" : false
        ));

        if (modalResult === false) return null;
        if (modalResult === true) return { ...existingContact, existing: true };
      }

      dispatch({ type: ContactsActions.ADD_NEW_CONTACT_START });

      const { BUSINESSES, VENDORS } = MainApiRoutes;

      const selectedBusinessId = getSelectedBusinessId(getState());

      const contact = await mainApi.put(
        `${BUSINESSES}/${selectedBusinessId + VENDORS}`,
        null,
        contactData
      );

      if (contact.id) {
        dispatch({ type: ContactsActions.ADD_NEW_CONTACT_DONE, payload: { contact } });
        toast.success(messages.contactAdded);

        return contact;
      }
      dispatch({ type: ContactsActions.ADD_NEW_CONTACT_ERROR });
      toast.error(errors.whileAddingContact);

      return null;
    };
  }

  static editContact(contactId, contactData) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.EDIT_CONTACT_START });

      const { messages, errors } = getTextsData(getState());

      const contact = await mainApi.patch(
        `${MainApiRoutes.VENDORS}/${contactId}`,
        null,
        contactData
      );

      if (contact.id) {
        dispatch({ type: ContactsActions.EDIT_CONTACT_DONE, payload: { contact } });
        toast.success(messages.contactEdited);

        return contact;
      }
      dispatch({ type: ContactsActions.EDIT_CONTACT_ERROR });
      toast.error(errors.whileEditingContact);

      return null;
    };
  }

  static deleteContact(contactId) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.DELETE_CONTACT_START });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.VENDORS}/${contactId}`);

      if (ok) {
        dispatch({ type: ContactsActions.DELETE_CONTACT_DONE, payload: { contactId } });
        toast.success(messages.contactDeleted);

        return contactId;
      }
      dispatch({ type: ContactsActions.DELETE_CONTACT_ERROR });
      toast.error(errors.whileDeletingContact);

      return null;
    };
  }

  static copyContact(contactData) {
    return async(dispatch, getState) => {
      dispatch({ type: ContactsActions.COPY_CONTACT_START });

      const { uiTexts, countries, messages, errors } = getTextsData(getState());

      const { name, idNumber, vatId, phone, email, countryCode, state, city, street, zipCode } = contactData;

      const contactText = [
        { label: uiTexts.name, value: name },
        { label: uiTexts.businessId, value: idNumber },
        { label: uiTexts.vatId, value: vatId },
        { label: uiTexts.phone, value: phone },
        { label: uiTexts.email, value: email },
        { label: uiTexts.address, value: [street, city, state, zipCode].filter((value) => value).join(", ") },
        { label: uiTexts.country, value: countries[countryCode] }
      ].filter(({ value }) => value).map(({ label, value }) => `${label}: ${value}`).join("\n");

      const { clipboard } = window.navigator;

      if (clipboard && clipboard.writeText) {
        await clipboard.writeText(contactText);
        dispatch({ type: ContactsActions.COPY_CONTACT_DONE });
        toast.info(messages.contactCopied);

        return messages.contactCopied;
      }
      dispatch({ type: ContactsActions.COPY_CONTACT_ERROR });
      toast.error(errors.unknown);

      return null;
    };
  }
}
