import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { mainApi } from "api";
import { toast } from "react-toastify";
import IntegrationServices from "const/IntegrationServices";
import MainApiRoutes from "const/MainApiRoutes";

export default class RoboticActions {
  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_START = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME";

  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE";

  static SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR = "businesses/SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR";

  static START_RPA_WORKFLOW_START = "robotic/START_RPA_WORKFLOW_START";

  static START_RPA_WORKFLOW_DONE = "robotic/START_RPA_WORKFLOW_DONE";

  static START_RPA_WORKFLOW_ERROR = "robotic/START_RPA_WORKFLOW_ERROR";

  static setQuickBooksAccountantClientName(businessId, clientBusinessName) {
    return async(dispatch) => {
      dispatch({ type: RoboticActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_START });

      const { BUSINESSES, EXTRA, CLIENT_NAME } = MainApiRoutes;

      const { ok } = await mainApi.patch(
        `${BUSINESSES}/${businessId}${EXTRA}/${IntegrationServices.QUICK_BOOKS.value}${CLIENT_NAME}`,
        null,
        { clientBusinessName }
      );

      if (ok) {
        dispatch({ type: RoboticActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_DONE });

        return true;
      }
      dispatch({ type: RoboticActions.SET_QUICKBOOKS_ACCOUNTANT_CLIENT_NAME_ERROR });

      return false;
    };
  }

  static startRpaWorkflow(workflow) {
    return async(dispatch, getState) => {
      dispatch({ type: RoboticActions.START_RPA_WORKFLOW_START });

      const { BUSINESSES, RPA, START } = MainApiRoutes;

      const { errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { ok } = await mainApi.post(`${BUSINESSES}/${selectedBusinessId}${RPA}/${workflow}${START}`);

      if (ok) {
        dispatch({ type: RoboticActions.START_RPA_WORKFLOW_DONE });

        return true;
      }
      dispatch({ type: RoboticActions.START_RPA_WORKFLOW_ERROR });
      toast.error(errors.unknown);

      return false;
    };
  }
}
