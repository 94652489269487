import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import Modal from "nlib/ui/Modal";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";

const AppModal = (props) => {
  const {
    headerText,
    text,
    size,
    confirm,
    okButtonText,
    cancelButtonText,
    cancelValue = false
  } = props;

  const dispatch = useDispatch();

  const { uiTexts = {} } = useSelector(getTextsData) || {};

  const title = headerText || (confirm ? uiTexts.confirm : uiTexts.notification);

  const handleConfirmClick = useCallback(() => {
    dispatch(UiActions.hideModal(true));
  }, [dispatch]);

  const handleCancelClick = useCallback(() => {
    dispatch(UiActions.hideModal(cancelValue));
  }, [cancelValue, dispatch]);

  const handleModalClose = useCallback(() => {
    dispatch(UiActions.hideModal(false));
  }, [dispatch]);

  return (
    <Modal
      size={size}
      title={title}
      className={Css.appModal}
      contentClassName={Css.contentClassName}
      iconComponent={confirm ? Icons.Question : Icons.Info}
      onClose={handleModalClose}>
      <div className={Css.content}>{text}</div>
      <div className={Css.actions}>
        <Button large outline onClick={handleCancelClick}>
          {cancelButtonText || (confirm ? uiTexts.cancel : uiTexts.close)}
        </Button>
        {confirm && (
          <Button large primary onClick={handleConfirmClick}>
            {okButtonText || uiTexts.ok}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(AppModal);
