import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import fallbackTexts from "assets/fallbackTexts.json";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { useDispatch } from "react-redux";
import AuthZeroActions from "actions/AuthZeroActions";
import Constants from "const/Constants";
import React, { useCallback, useEffect } from "react";
import classNames from "classnames";

const MaintenanceHandler = () => {
  const dispatch = useDispatch();

  const { uiTexts, errors } = fallbackTexts;

  const handleReloadButtonClick = useCallback(() => {
    window.location.reload();
  }, []);

  const handleLogoutButtonClick = useCallback(() => {
    dispatch(AuthZeroActions.logoutUser());
  }, [dispatch]);

  useEffect(() => {
    let reloadTimeoutId = null;

    reloadTimeoutId = setTimeout(
      () => window.location.reload(),
      Constants.LOGIN_PAGE_REFRESH_TIMEOUT
    );

    return () => {
      document.body.dataset.maintenance = "";
      clearInterval(reloadTimeoutId);
    };
  }, []);

  return (
    <div className={classNames(Css.maintenanceHandler, CommonCss.flexCenter)}>
      <div className={Css.content}>
        <div className={Css.iconContainer}>
          <Icons.Triangle weight="thin" className={classNames(Css.icon, Css.semiTransparent)} />
          <Icons.Wrench weight="fill" className={classNames(Css.extraIcon, Css.warning)} />
        </div>
        <div className={Css.title}>{errors.maintenanceTitle}</div>
        <div className={Css.description}>{errors.loadingApp}</div>
        <div className={Css.buttonContainer}>
          <Button large dark outline onClick={handleReloadButtonClick}>
            {uiTexts.reloadPage}
          </Button>
          <Button large onClick={handleLogoutButtonClick}>
            {uiTexts.logout}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MaintenanceHandler);
