import { checkTasksReadyToDisplay, getAutoTasksRawData, getTasksData, getTasksRawData } from "selectors/tasks";
import { getAccountsData } from "selectors/accounts";
import { getEnvVars } from "selectors/envVars";
import { getGlobalStats, getSelectedBusinessId } from "selectors/businesses";
import { getRouterData } from "selectors/router";
import { getTextsData } from "selectors/texts";
import { getUserData } from "selectors/user";
import { mainApi } from "api";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import Async from "utils/Async";
import DataConstants from "const/DataConstants";
import MainApiRoutes from "const/MainApiRoutes";
import Pages from "nlib/pages/Pages";
import Tasks from "utils/Tasks";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import objectHash from "object-hash";

const { TASK_STATUSES } = DataConstants;

export default class TasksActions {
  static FETCH_TASKS_LIST_START = "tasks/FETCH_TASKS_LIST_START";

  static FETCH_TASKS_LIST_DONE = "tasks/FETCH_TASKS_LIST_DONE";

  static FETCH_TASKS_LIST_ERROR = "tasks/FETCH_TASKS_LIST_ERROR";

  static FETCH_TASK_START = "tasks/FETCH_TASK_START";

  static FETCH_TASK_DONE = "tasks/FETCH_TASK_DONE";

  static FETCH_TASK_ERROR = "tasks/FETCH_TASK_ERROR";

  static ADD_NEW_TASK_START = "tasks/ADD_NEW_TASK_START";

  static ADD_NEW_TASK_DONE = "tasks/ADD_NEW_TASK_DONE";

  static ADD_NEW_TASK_ERROR = "tasks/ADD_NEW_TASK_ERROR";

  static EDIT_TASK_START = "tasks/EDIT_TASK_START";

  static EDIT_TASK_DONE = "tasks/EDIT_TASK_DONE";

  static EDIT_TASK_ERROR = "tasks/EDIT_TASK_ERROR";

  static BULK_EDIT_TASKS_START = "tasks/BULK_EDIT_TASKS_START";

  static BULK_EDIT_TASKS_DONE = "tasks/BULK_EDIT_TASKS_DONE";

  static BULK_EDIT_TASKS_ERROR = "tasks/BULK_EDIT_TASKS_ERROR";

  static DELETE_TASK_START = "tasks/DELETE_TASK_START";

  static DELETE_TASK_DONE = "tasks/DELETE_TASK_DONE";

  static DELETE_TASK_ERROR = "tasks/DELETE_TASK_ERROR";

  static CLEAR_TASKS = "tasks/CLEAR";

  static SET_TASKS_READY_TO_DISPLAY = "tasks/SET_TASKS_READY_TO_DISPLAY";

  static checkAndUpdateAutoTasksStatus() {
    return async(dispatch, getState) => {
      const globalStats = getGlobalStats(getState());

      if (!globalStats.transactions || !globalStats.documents || !globalStats.audit) return;

      const tasksReadyToDisplay = checkTasksReadyToDisplay(getState());

      const autoTasksData = getAutoTasksRawData(getState());

      if (autoTasksData.length) {
        const accountsData = getAccountsData(getState());

        const { location: { pathname } } = getRouterData(getState());

        const { route: currentRoute } = Pages.getCurrentRouteInfo(pathname);

        const needMarkAsCompletedIds = autoTasksData
          .filter(({ status }) => status !== TASK_STATUSES.COMPLETED)
          .filter(({ type, startDate }) => {
            return Tasks.checkIsAutoTaskCompleted({ type, startDate, globalStats, accountsData });
          })
          .map(({ id }) => id);

        const needMarkAsInToDoIds = autoTasksData
          .filter(({ status }) => status === TASK_STATUSES.COMPLETED)
          .filter(({ type, startDate }) => {
            return !Tasks.checkIsAutoTaskCompleted({ type, startDate, globalStats, accountsData });
          })
          .map(({ id }) => id);

        if (needMarkAsCompletedIds.length) {
          await dispatch(TasksActions.bulkEditTasks(
            needMarkAsCompletedIds,
            { status: TASK_STATUSES.COMPLETED },
            true,
            true
          ));
        }

        if (needMarkAsInToDoIds.length) {
          await dispatch(TasksActions.bulkEditTasks(
            needMarkAsInToDoIds,
            { status: TASK_STATUSES.TO_DO },
            true,
            true
          ));
        }

        if (currentRoute === UiRoutes.TASKS && needMarkAsCompletedIds.length) {
          const { messages } = getTextsData(getState());

          toast.info(
            Utils.replaceTextVars(messages.tasksAutoCompleted, { count: needMarkAsCompletedIds.length })
          );
        }
      }

      if (!tasksReadyToDisplay) {
        dispatch({ type: TasksActions.SET_TASKS_READY_TO_DISPLAY, payload: true });
      }
    };
  }

  static fetchTasksList(clearList = false, backgroundUpdate = false) {
    return async(dispatch, getState) => {
      if (clearList) {
        dispatch({ type: TasksActions.SET_TASKS_READY_TO_DISPLAY, payload: false });
      }
      dispatch({ type: TasksActions.FETCH_TASKS_LIST_START, payload: { clearList, backgroundUpdate } });

      const { errors, messages } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { BUSINESSES, TASKS } = MainApiRoutes;

      const path = `${BUSINESSES}/${selectedBusinessId}${TASKS}`;

      const { results: tasks } = await mainApi.get(path);

      if (Array.isArray(tasks)) {
        const { editTask = "" } = getEnvVars(getState());

        const [editableTaskId] = editTask.split(".");

        const state = getState();

        const { tasks: { dataHash: oldDataHash } } = state;

        if (oldDataHash) {
          const userData = getUserData(state);

          const [oldActiveTasksIds, newActiveTasksIds] = [getTasksData(state), getTasksData({ ...state, tasks: { data: tasks } })]
            .map((items) => {
              return items.filter(({ completed, createdBy, targetUser }) => {
                return !completed && createdBy && targetUser && (targetUser.id === userData.id);
              }).map(({ id }) => id);
            });

          if (newActiveTasksIds.some((id) => !oldActiveTasksIds.includes(id))) {
            toast.info(messages.taskAssigned);
          }
        }

        const dataHash = objectHash({ value: tasks });

        dispatch({
          type: TasksActions.FETCH_TASKS_LIST_DONE,
          payload: { editableTaskId, tasks, dataHash }
        });

        return tasks;
      }
      dispatch({ type: TasksActions.FETCH_TASKS_LIST_ERROR });
      if (!backgroundUpdate) toast.error(errors.whileLoadingTasks);

      return null;
    };
  }

  static fetchTask(taskId) {
    return async(dispatch, getState) => {
      dispatch({ type: TasksActions.FETCH_TASK_START });

      const { errors } = getTextsData(getState());

      const { TASKS } = MainApiRoutes;

      const path = `${TASKS}/${taskId}`;

      const task = await mainApi.get(path);

      if (task.id) {
        dispatch({ type: TasksActions.FETCH_TASK_DONE, payload: { task } });

        return task;
      }
      dispatch({ type: TasksActions.FETCH_TASK_ERROR });
      toast.error(errors.whileLoadingTask);

      return null;
    };
  }

  static addNewTask({ files, ...payload }) {
    return async(dispatch, getState) => {
      const { messages, errors } = getTextsData(getState());

      const selectedBusinessId = getSelectedBusinessId(getState());

      dispatch({ type: TasksActions.ADD_NEW_TASK_START });

      const { BUSINESSES, TASKS, ATTACHMENTS } = MainApiRoutes;

      const uploadPath = `${BUSINESSES}/${selectedBusinessId + ATTACHMENTS}`;

      const filesData = files ? (await Async.runInSequence(files.map((file) => {
        return async() => {
          const { key, originalName } = await mainApi.put(uploadPath, null, file, "document");

          if (!key) toast.error(Utils.replaceTextVars(errors.whileUploadingFile, { fileName: file.name }));

          return key ? { key, originalName } : null;
        };
      }))).filter(Boolean) : [];

      const path = `${BUSINESSES}/${selectedBusinessId + TASKS}`;

      const task = await mainApi.put(path, null, { ...payload, filesData });

      if (task.id) {
        dispatch({ type: TasksActions.ADD_NEW_TASK_DONE, payload: { task } });
        toast.success(messages.taskAdded);

        return task;
      }
      dispatch({ type: TasksActions.ADD_NEW_TASK_ERROR });
      toast.error(errors.whileAddingTask);

      return null;
    };
  }

  static editTask(taskId, { files, ...payload }, optimisticUpdate = false, silentUpdate) {
    return async(dispatch, getState) => {
      const { messages, errors } = getTextsData(getState());

      const prevTasks = getTasksRawData(getState());

      const prevTaskData = prevTasks.find(({ id }) => id === taskId);

      const selectedBusinessId = getSelectedBusinessId(getState());

      if (optimisticUpdate && prevTaskData) {
        dispatch({
          type: TasksActions.EDIT_TASK_START,
          payload: {
            task: {
              id: taskId,
              ...payload,
              ...(files?.length
                ? {
                  attachments: [
                    ...prevTaskData.attachments,
                    ...files.map(({ name }) => ({ loading: true, originalName: name, id: uuid() }))
                  ]
                }
                : null)
            }
          }
        });
      }

      const { BUSINESSES, TASKS, ATTACHMENTS } = MainApiRoutes;

      const uploadPath = `${BUSINESSES}/${selectedBusinessId + ATTACHMENTS}`;

      const filesData = files?.length ? (await Async.runInSequence(files.map((file) => {
        return async() => {
          const { key, originalName } = await mainApi.put(uploadPath, null, file, "document");

          if (!key) toast.error(Utils.replaceTextVars(errors.whileUploadingFile, { fileName: file.name }));

          return key ? { key, originalName } : null;
        };
      }))).filter(Boolean) : undefined;

      const path = `${BUSINESSES}/${selectedBusinessId}${TASKS}/${taskId}`;

      const task = await mainApi.post(path, null, { ...payload, attachments: prevTaskData?.attachments, filesData });

      if (task.id) {
        dispatch({ type: TasksActions.EDIT_TASK_DONE, payload: { task } });
        if (!silentUpdate) toast.success(messages.taskEdited);

        return task;
      }
      dispatch({ type: TasksActions.EDIT_TASK_ERROR });
      toast.error(errors.whileEditingTask);

      if (optimisticUpdate) {
        dispatch({
          type: TasksActions.FETCH_TASKS_LIST_DONE,
          payload: { tasks: prevTasks }
        });
      }

      return null;
    };
  }

  static bulkEditTasks(ids, data, backgroundUpdate = false, silentUpdate = false) {
    return async(dispatch, getState) => {
      dispatch({ type: TasksActions.BULK_EDIT_TASKS_START, payload: { backgroundUpdate } });

      const selectedBusinessId = getSelectedBusinessId(getState());

      const { messages, errors } = getTextsData(getState());

      const { BUSINESSES, TASKS, BULK } = MainApiRoutes;

      const path = `${BUSINESSES}/${selectedBusinessId}${TASKS}${BULK}`;

      const { results: tasks } = await mainApi.patch(path, null, { ids, data });

      if (Array.isArray(tasks) && tasks.length) {
        dispatch({ type: TasksActions.BULK_EDIT_TASKS_DONE, payload: { tasks } });
        if (!silentUpdate) toast.success(messages.tasksEdited);

        return tasks;
      }
      dispatch({ type: TasksActions.BULK_EDIT_TASKS_ERROR });
      toast.error(errors.whileEditingTasks);

      return null;
    };
  }

  static deleteTask(taskId) {
    return async(dispatch, getState) => {
      dispatch({ type: TasksActions.DELETE_TASK_START, payload: { taskId } });

      const { messages, errors } = getTextsData(getState());

      const { ok } = await mainApi.delete(`${MainApiRoutes.TASKS}/${taskId}`);

      if (ok) {
        dispatch({ type: TasksActions.DELETE_TASK_DONE, payload: { taskId } });
        toast.success(messages.taskDeleted);

        return taskId;
      }
      dispatch({ type: TasksActions.DELETE_TASK_ERROR });
      toast.error(errors.whileDeletingTask);

      return null;
    };
  }

  static clearTasks() {
    return (dispatch) => {
      dispatch({ type: TasksActions.CLEAR_TASKS });
    };
  }
}
